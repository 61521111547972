/* eslint-disable ternaries/no-empty-ternary */
import { Button, List, ListItem, ListItemButton, ListItemText, Typography } from "@mui/material";
import { ApproverInfo, Auth } from "../API";
import SendIcon from "@mui/icons-material/Send";
import { AuthenticationState } from "../enums/authentication-state";

export const AssistedLoginButtons = ({
  auth,
  callback,
  setTouchAcceptReplies,
  touchAcceptReplies,
  approvers,
  formValid,
  sending,
}: {
  auth: Auth;
  setTouchAcceptReplies: (touchAcceptReplies: Map<string, string>) => void;
  touchAcceptReplies: Map<string, string>;
  callback: (
    auth: Auth,
    authState: AuthenticationState,
    approver: string | undefined,
    touchAcceptReplies: Map<string, string>
  ) => void;
  approvers: Array<ApproverInfo>;
  formValid: boolean;
  sending: boolean;
}) => {
  return (
    <>
      <div className="assisted-login-buttons-container">
        <Typography variant="h6" gutterBottom component="div">
          Select approver to request access from:
        </Typography>
        <div className="assisted-login-buttons">
          <List sx={{ maxHeight: 300, width: `100%`, overflow: "auto" }} dense>
            {approvers.map((approver, id) => (
              <ListItem key={id}>
                <ListItemButton
                  className="assisted-login-button"
                  disabled={!formValid}
                  onClick={() => {
                    touchAcceptReplies.set("1", approver.label);
                    setTouchAcceptReplies(touchAcceptReplies);
                    callback(auth, AuthenticationState.ACCEPTED, approver.id, touchAcceptReplies);
                  }}
                >
                  <Button
                    sx={{ width: "100%" }}
                    variant="contained"
                    color="success"
                    size="small"
                    disabled={sending}
                    endIcon={<SendIcon />}
                  >
                    <ListItemText primary={approver?.label} />
                  </Button>
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </div>
        <Button
          variant="contained"
          color="error"
          disabled={sending}
          onClick={() => {
            callback(auth, AuthenticationState.REJECTED, undefined, touchAcceptReplies);
          }}
        >
          {"Cancel Authentication"}
        </Button>
      </div>
    </>
  );
};
