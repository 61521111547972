import "./App.css";
import { TouchAccept } from "./components/TouchAccept";
import { Grid } from "@mui/material";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

function App() {
  const urlParams = new URLSearchParams(window.location.search);
  const id = urlParams.get("id") || "12123123";
  const r = urlParams.get("r") || undefined;

  // askPermissionForNotifications();

  const darkTheme = createTheme({
    palette: {
      mode: "dark",
    },
  });

  return (
    <ThemeProvider theme={darkTheme}>
      <CssBaseline>
        <div className="App">
          <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="center"
            style={{ minHeight: "100vh" }}
          >
            <Grid>
              <TouchAccept id={id} r={r} />
            </Grid>
          </Grid>
        </div>
      </CssBaseline>
    </ThemeProvider>
  );

  function isNotificationSupported() {
    const newLocal = "Notification" in window && "serviceWorker" in navigator && "PushManager" in window;
    return newLocal;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  function askPermissionForNotifications() {
    if (isNotificationSupported()) {
      Notification.requestPermission().then((result) => {
        if (result === "granted") {
          console.log("XXX: Got perms");
        } else {
          console.log("XXX: No perms");
        }
      });
    } else {
      console.log("Notifications NOT supported by this device");
    }
  }
}

export default App;
