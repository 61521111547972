import { Button, Divider, Stack } from "@mui/material";
import { Android, Apple } from "@mui/icons-material";

import Typography from "@mui/material/Typography";
import { Auth } from "../API";

export const ActivateMideyePlus = ({ auth, activationCode }: { auth: Auth; activationCode: string }) => {
  let activationReferenceId = activationCode;
  if (activationCode.length !== 64) {
    const strippedSessionId = auth.id.replaceAll("-", "");
    activationReferenceId = strippedSessionId + activationCode;
  }
  return (
    <div className="promotion">
      <Divider orientation="horizontal" />
      <Stack p={2}>
        <Typography variant="body2" gutterBottom component="div">
          The Mideye+ application makes the authentication procedure easier by reducing the number of steps required by
          the user during authentication.
        </Typography>
        <Typography variant="body2" gutterBottom component="div">
          Click on the activate button below to activate the Mideye+ application.
        </Typography>
        <Stack spacing={2} direction="row" justifyContent="center" pt={2}>
          <Button
            variant="contained"
            color="success"
            href={`https://plus.mideye.com/app/?o=1&r=${activationReferenceId}`}
          >
            Activate Mideye+
          </Button>
        </Stack>
        <Typography variant="body2" gutterBottom component="div" pt={2}>
          Click on the link below to download the Mideye+ app for your phone.
        </Typography>
        <Stack spacing={2} direction="column" justifyContent="center" pt={2}>
          <Button
            variant="contained"
            color="success"
            startIcon={<Android />}
            href="https://play.google.com/store/apps/details?id=com.mideye.plusapp&pcampaignid=MKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
          >
            Download Android client
          </Button>
          <Button
            variant="contained"
            color="success"
            startIcon={<Apple />}
            href="https://itunes.apple.com/us/app/mideye/id521987945"
          >
            Download IOS client
          </Button>
        </Stack>
      </Stack>
    </div>
  );
};
