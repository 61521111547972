/* eslint-disable ternaries/no-empty-ternary */
import { Button, Stack } from "@mui/material";
import { Auth } from "../API";
import { AuthenticationState } from "../enums/authentication-state";

export const TouchAcceptButtons = ({
  auth,
  callback,
  touchAcceptReplies,
  formValid,
  sending,
}: {
  auth: Auth;
  touchAcceptReplies: Map<string, string>;
  callback: (
    auth: Auth,
    authState: AuthenticationState,
    approver: string | undefined,
    touchAcceptReplies: Map<string, string>
  ) => void;
  formValid: boolean;
  sending: boolean;
}) => {
  return (
    <Stack spacing={2} direction="row" mt={5}>
      <Button
        disabled={!formValid || sending}
        variant="contained"
        onClick={() => {
          callback(auth, AuthenticationState.ACCEPTED, undefined, touchAcceptReplies);
        }}
      >
        {auth?.acceptText ? auth?.acceptText : "Accept"}
      </Button>

      <Button
        variant="contained"
        color="error"
        disabled={!formValid || sending}
        onClick={() => {
          callback(auth, AuthenticationState.REJECTED, undefined, touchAcceptReplies);
        }}
      >
        {auth?.rejectText ? auth?.rejectText : "Reject"}
      </Button>
    </Stack>
  );
};
