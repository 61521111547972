/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const updateAuth = /* GraphQL */ `
  mutation UpdateAuth(
    $input: UpdateAuthInput!
    $condition: ModelAuthConditionInput
  ) {
    updateAuth(input: $input, condition: $condition) {
      id
      touchAccepted
      challengeResponses {
        id
        value
      }
    }
  }
`;
