/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getAuth = /* GraphQL */ `
  query GetAuth($id: ID!) {
    getAuth(id: $id) {
      id
      touchAccepted
      state
      title
      touchAcceptInfo
      acceptedInfo
      rejectedInfo
      expiresAt
      acceptText
      rejectText
      challenges {
        id
        helperText
        type
        required
        label
        choices {
          id
          label
          value
        }
      }
      challengeResponses {
        id
        value
      }
      approvers {
        id
        label
        msisdn
        description
      }
      createdAt
      updatedAt
    }
  }
`;
