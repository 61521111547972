import CircularProgress from "@mui/material/CircularProgress";

import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { useEffect, useState } from "react";
import { AuthenticationState } from "../enums/authentication-state";

export const CircularProgressWithLabel = ({
  createdAt,
  expiresAt,
  state,
  setState,
  setInfo,
}: {
  createdAt: number;
  expiresAt: number;
  state: AuthenticationState;
  setState: (state: AuthenticationState) => void;
  setInfo: (details: [string]) => void;
}) => {
  const now = Date.now();
  const [currentTime, setCurrentTime] = useState(now);

  useEffect(() => {
    const timer = setInterval(() => {
      if (state === AuthenticationState.PENDING) {
        setCurrentTime(Date.now());
        if (expiresAt && currentTime > expiresAt) {
          setState(AuthenticationState.EXPIRED);
          setInfo(["Authentication expired"]);
        }
      } else {
        clearInterval(timer);
      }
    }, 500);
    return () => {
      clearInterval(timer);
    };
  }, [expiresAt, currentTime, state, setState, setInfo]);

  const remaining = expiresAt - currentTime;
  const running = currentTime - createdAt;
  const total = expiresAt - createdAt;
  const value = Math.floor(100 * ((total - running) / total));

  return (
    <>
      <Box sx={{ position: "relative", display: "inline-flex" }}>
        <CircularProgress variant="determinate" color="info" size="100px" value={value} />
        <Box
          sx={{
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            position: "absolute",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {remaining > 0 && <Typography variant="h5" component="div">{`${Math.floor(remaining / 1000)} s`}</Typography>}
        </Box>
      </Box>
    </>
  );
};
