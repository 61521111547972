import { Box, Stack } from "@mui/material";
import { ChallengeRequest, ChallengeType } from "../API";
import { TouchAcceptChallenge } from "./TouchAcceptChallenge";

export const TouchAcceptChallenges = ({
  challenges,
  setTouchAcceptReplies,
  touchAcceptReplies,
}: {
  challenges: ChallengeRequest[];
  touchAcceptReplies: Map<string, string>;
  setTouchAcceptReplies: any;
}) => {
  return (
    <Box sx={{ width: "100%" }}>
      <Stack spacing={2}>
        {challenges
          .filter((challenge) => challenge.type !== ChallengeType.SELECT)
          .map((challenge: ChallengeRequest) => {
            return (
              <div key={challenge.id}>
                <TouchAcceptChallenge
                  challenge={challenge}
                  setTouchAcceptReplies={setTouchAcceptReplies}
                  touchAcceptReplies={touchAcceptReplies}
                />
              </div>
            );
          })}
      </Stack>
    </Box>
  );
};
