/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.

export type UpdateAuthInput = {
  id: string,
  touchAccepted?: boolean | null,
  state?: AuthState | null,
  mideyeServerId?: string | null,
  title?: string | null,
  touchAcceptInfo?: Array< string | null > | null,
  acceptedInfo?: Array< string | null > | null,
  rejectedInfo?: Array< string | null > | null,
  expiresAt?: string | null,
  acceptText?: string | null,
  rejectText?: string | null,
  challenges?: Array< ChallengeRequestInput | null > | null,
  challengeResponses?: Array< ChallengeResponseInput | null > | null,
  approvers?: Array< ApproverInfoInput | null > | null,
};

export enum AuthState {
  STARTED = "STARTED",
  ACCEPTED = "ACCEPTED",
  WAITING_FOR_APPROVAL = "WAITING_FOR_APPROVAL",
  REJECTED_BY_APPROVEE = "REJECTED_BY_APPROVEE",
  REJECTED_BY_APPROVER = "REJECTED_BY_APPROVER",
  TIMED_OUT_AT_APPROVEE = "TIMED_OUT_AT_APPROVEE",
  TIMED_OUT_AT_APPROVER = "TIMED_OUT_AT_APPROVER",
}


export type ChallengeRequestInput = {
  id: string,
  helperText?: string | null,
  type: ChallengeType,
  required?: boolean | null,
  label?: string | null,
  choices?: Array< SelectChoiceInput | null > | null,
};

export enum ChallengeType {
  STRING = "STRING",
  SELECT = "SELECT",
}


export type SelectChoiceInput = {
  id: string,
  label: string,
  value: string,
};

export type ChallengeResponseInput = {
  id: string,
  value?: string | null,
};

export type ApproverInfoInput = {
  id: string,
  label: string,
  msisdn?: string | null,
  description?: string | null,
};

export type ModelAuthConditionInput = {
  touchAccepted?: ModelBooleanInput | null,
  state?: ModelAuthStateInput | null,
  mideyeServerId?: ModelStringInput | null,
  title?: ModelStringInput | null,
  touchAcceptInfo?: ModelStringInput | null,
  acceptedInfo?: ModelStringInput | null,
  rejectedInfo?: ModelStringInput | null,
  expiresAt?: ModelStringInput | null,
  acceptText?: ModelStringInput | null,
  rejectText?: ModelStringInput | null,
  and?: Array< ModelAuthConditionInput | null > | null,
  or?: Array< ModelAuthConditionInput | null > | null,
  not?: ModelAuthConditionInput | null,
};

export type ModelBooleanInput = {
  ne?: boolean | null,
  eq?: boolean | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export enum ModelAttributeTypes {
  binary = "binary",
  binarySet = "binarySet",
  bool = "bool",
  list = "list",
  map = "map",
  number = "number",
  numberSet = "numberSet",
  string = "string",
  stringSet = "stringSet",
  _null = "_null",
}


export type ModelAuthStateInput = {
  eq?: AuthState | null,
  ne?: AuthState | null,
};

export type ModelStringInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export type ModelSizeInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
};

export type Auth = {
  __typename: "Auth",
  id: string,
  touchAccepted?: boolean | null,
  state?: AuthState | null,
  mideyeServerId: string,
  title?: string | null,
  touchAcceptInfo?: Array< string | null > | null,
  acceptedInfo?: Array< string | null > | null,
  rejectedInfo?: Array< string | null > | null,
  expiresAt: string,
  acceptText?: string | null,
  rejectText?: string | null,
  challenges?:  Array<ChallengeRequest | null > | null,
  challengeResponses?:  Array<ChallengeResponse | null > | null,
  approvers?:  Array<ApproverInfo | null > | null,
  createdAt: string,
  updatedAt: string,
};

export type ChallengeRequest = {
  __typename: "ChallengeRequest",
  id: string,
  helperText?: string | null,
  type: ChallengeType,
  required?: boolean | null,
  label?: string | null,
  choices?:  Array<SelectChoice | null > | null,
};

export type SelectChoice = {
  __typename: "SelectChoice",
  id: string,
  label: string,
  value: string,
};

export type ChallengeResponse = {
  __typename: "ChallengeResponse",
  id: string,
  value?: string | null,
};

export type ApproverInfo = {
  __typename: "ApproverInfo",
  id: string,
  label: string,
  msisdn?: string | null,
  description?: string | null,
};

export type UpdateAuthMutationVariables = {
  input: UpdateAuthInput,
  condition?: ModelAuthConditionInput | null,
};

export type UpdateAuthMutation = {
  updateAuth?:  {
    __typename: "Auth",
    id: string,
    touchAccepted?: boolean | null,
    state?: AuthState | null,
    mideyeServerId: string,
    title?: string | null,
    touchAcceptInfo?: Array< string | null > | null,
    acceptedInfo?: Array< string | null > | null,
    rejectedInfo?: Array< string | null > | null,
    expiresAt: string,
    acceptText?: string | null,
    rejectText?: string | null,
    challenges?:  Array< {
      __typename: "ChallengeRequest",
      id: string,
      helperText?: string | null,
      type: ChallengeType,
      required?: boolean | null,
      label?: string | null,
      choices?:  Array< {
        __typename: "SelectChoice",
        id: string,
        label: string,
        value: string,
      } | null > | null,
    } | null > | null,
    challengeResponses?:  Array< {
      __typename: "ChallengeResponse",
      id: string,
      value?: string | null,
    } | null > | null,
    approvers?:  Array< {
      __typename: "ApproverInfo",
      id: string,
      label: string,
      msisdn?: string | null,
      description?: string | null,
    } | null > | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type GetAuthQueryVariables = {
  id: string,
};

export type GetAuthQuery = {
  getAuth?:  {
    __typename: "Auth",
    id: string,
    touchAccepted?: boolean | null,
    state?: AuthState | null,
    mideyeServerId: string,
    title?: string | null,
    touchAcceptInfo?: Array< string | null > | null,
    acceptedInfo?: Array< string | null > | null,
    rejectedInfo?: Array< string | null > | null,
    expiresAt: string,
    acceptText?: string | null,
    rejectText?: string | null,
    challenges?:  Array< {
      __typename: "ChallengeRequest",
      id: string,
      helperText?: string | null,
      type: ChallengeType,
      required?: boolean | null,
      label?: string | null,
      choices?:  Array< {
        __typename: "SelectChoice",
        id: string,
        label: string,
        value: string,
      } | null > | null,
    } | null > | null,
    challengeResponses?:  Array< {
      __typename: "ChallengeResponse",
      id: string,
      value?: string | null,
    } | null > | null,
    approvers?:  Array< {
      __typename: "ApproverInfo",
      id: string,
      label: string,
      msisdn?: string | null,
      description?: string | null,
    } | null > | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateAuthSubscriptionVariables = {
  mideyeServerId: string,
};

export type OnUpdateAuthSubscription = {
  onUpdateAuth?:  {
    __typename: "Auth",
    id: string,
    touchAccepted?: boolean | null,
    state?: AuthState | null,
    mideyeServerId: string,
    title?: string | null,
    touchAcceptInfo?: Array< string | null > | null,
    acceptedInfo?: Array< string | null > | null,
    rejectedInfo?: Array< string | null > | null,
    expiresAt: string,
    acceptText?: string | null,
    rejectText?: string | null,
    challenges?:  Array< {
      __typename: "ChallengeRequest",
      id: string,
      helperText?: string | null,
      type: ChallengeType,
      required?: boolean | null,
      label?: string | null,
      choices?:  Array< {
        __typename: "SelectChoice",
        id: string,
        label: string,
        value: string,
      } | null > | null,
    } | null > | null,
    challengeResponses?:  Array< {
      __typename: "ChallengeResponse",
      id: string,
      value?: string | null,
    } | null > | null,
    approvers?:  Array< {
      __typename: "ApproverInfo",
      id: string,
      label: string,
      msisdn?: string | null,
      description?: string | null,
    } | null > | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};
