/* eslint-disable ternaries/no-empty-ternary */
import { Box, InputLabel } from "@mui/material";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { ReactNode } from "react";
import { ChallengeRequest, ChallengeType, SelectChoice } from "../API";

export const TouchAcceptChallenge = ({
  challenge: challengeRequest,
  setTouchAcceptReplies,
  touchAcceptReplies,
}: {
  challenge: ChallengeRequest;
  touchAcceptReplies: Map<string, string>;
  setTouchAcceptReplies: any;
}) => {
  if (challengeRequest.type === ChallengeType.STRING) {
    return (
      <>
        <TextField
          id={challengeRequest.id}
          type="text"
          label={challengeRequest.label}
          name={challengeRequest.id}
          variant="standard"
          required={challengeRequest.required ? true : false}
          style={{ width: "100%" }}
          defaultValue={""}
          helperText={challengeRequest.helperText}
          onChange={(e) => {
            touchAcceptReplies.set(challengeRequest.id, e.target.value);
            setTouchAcceptReplies(touchAcceptReplies);
          }}
        />
      </>
    );
  } else if (challengeRequest.type === ChallengeType.SELECT) {
    const selectValues = challengeRequest.choices as unknown as SelectChoice[];
    return selectValues ? (
      <>
        <InputLabel id="label">{challengeRequest.helperText}</InputLabel>
        <Select
          id={challengeRequest.id}
          type="text"
          label={challengeRequest.helperText}
          name={challengeRequest.id}
          variant="standard"
          style={{ width: "100%" }}
          defaultValue=""
          required={challengeRequest.required ? true : false}
          onChange={(event: SelectChangeEvent<string>, child: ReactNode) => {
            touchAcceptReplies.set(challengeRequest.id, event.target.value);
            setTouchAcceptReplies(touchAcceptReplies);
          }}
        >
          {selectValues.map((selectValue: SelectChoice) => {
            return (
              <MenuItem key={selectValue.id} value={selectValue.value}>
                {selectValue.label}
              </MenuItem>
            );
          })}
        </Select>
      </>
    ) : (
      <></>
    );
  } else {
    return <Box>Unknown type: {challengeRequest.type}</Box>;
  }
};
