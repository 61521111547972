/* eslint-disable ternaries/no-empty-ternary */
import { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import { CircularProgressWithLabel } from "./CircularProgressWithLabel";
import { AuthenticationState } from "../enums/authentication-state";
import { ApproverInfo, Auth, ChallengeRequest, ChallengeResponse } from "../API";
import { ActivateMideyePlus } from "./ActivateMideyePlus";
import { TouchAcceptChallenges } from "./TouchAcceptChallenges";
import { AssistedLoginButtons } from "./AssistedLoginButtons";
import { TouchAcceptButtons } from "./TouchAccepButtons";
import { parseApprovers, queryAuth, isFormValid, parseChallenges } from "../utils/graphql";
import { API, graphqlOperation } from "aws-amplify";
import { updateAuth } from "../graphql/mutations";

export interface ITouchAcceptParams {
  id?: string;
  r?: string;
}

export const TouchAccept = ({ id, r }: ITouchAcceptParams) => {
  const [state, setState] = useState(AuthenticationState.LOADING);
  const [auth, setAuth] = useState<Auth>();
  const [info, setInfo] = useState(["Press accept to login"]);
  const [activationCode, setActivationCode] = useState("");
  const [touchAcceptReplies, setTouchAcceptReplies] = useState(new Map<string, string>());
  const [formValid, setFormValid] = useState(false);
  const [sending, setSending] = useState(false);
  const [expiresAt, setExpiresAt] = useState(Date.now() + 1000 * 3600);
  const [createdAt, setCreatedAt] = useState(Date.now());
  const [approvers, setApprovers] = useState<Array<ApproverInfo> | undefined>(undefined);

  useEffect(() => {
    if (id) {
      queryAuth(id, r)
        .then((auth) => {
          setState(auth.state);
          setInfo(auth.info);
          setAuth(auth.auth);
          setActivationCode(auth.activationCode);
        })
        .catch((err) => {
          console.log("Failed to load auth session: ", err);
        });
    }
  }, [id, r]);

  useEffect(() => {
    if (auth) {
      setApprovers(parseApprovers(auth));

      var challengesReplies = undefined;
      if (auth.challenges) {
        challengesReplies = parseChallenges(auth.challenges);
        if (challengesReplies && challengesReplies?.size > 0) {
          setTouchAcceptReplies(challengesReplies);
        }
      }

      // Parse data for countdown timer
      const validity = {
        createdAt: Date.parse(auth.createdAt),
        expiresAt: Date.parse(auth.expiresAt),
      };
      const now = Date.now();
      if (validity.expiresAt - Date.now() > +1000 * 3600) {
        // This should be a demo timer
        setExpiresAt(now + 1000 * 120);
        setCreatedAt(now);
      } else {
        setExpiresAt(validity.expiresAt);
        setCreatedAt(validity.createdAt);
      }

      setFormValid(isFormValid(auth, challengesReplies));
    }
  }, [auth]);

  const onRespondToTA = async (
    auth: Auth,
    authState: AuthenticationState,
    selectedApprover: string | undefined,
    touchAcceptReplies: Map<string, string>
  ) => {
    const touchAccepted = authState.valueOf() === AuthenticationState.ACCEPTED.valueOf();
    setSending(true);
    try {
      const challengeResponses = Array.from(touchAcceptReplies, ([key, value]) => {
        return {
          id: key,
          value: value,
        } as ChallengeResponse;
      });

      const updatedAuth = {
        id: auth.id,
        touchAccepted,
        challengeResponses,
        selectedApprover,
      };
      (await API.graphql(graphqlOperation(updateAuth, { input: updatedAuth }))) as any;
      if (touchAccepted) {
        const acceptedInfo = auth.acceptedInfo as string[];
        if (acceptedInfo) {
          setInfo(acceptedInfo);
        } else {
          setInfo(["Authentication request was accepted"]);
        }
        setState(AuthenticationState.ACCEPTED);
      } else {
        const rejectedInfo = auth.rejectedInfo as string[];
        setInfo(rejectedInfo ? rejectedInfo : ["Authentication request was rejected"]);
        setState(AuthenticationState.REJECTED);
      }
    } catch (e) {
      console.log("error updating auth session", e);
      setState(AuthenticationState.ERROR);
      if (e instanceof Error) {
        setInfo(e.message ? [e.message] : ["Failed to send result to server"]);
      } else {
        setInfo(["Failed to send result to server"]);
      }
    } finally {
      setSending(false);
    }
  };

  if (!auth) {
    return <div className="authentication-box">Loading...</div>;
  }

  return (
    <div className="authentication-box">
      <div className="header" />
      <div className={state === AuthenticationState.PENDING ? "text-pending" : "text"}>
        <Typography variant="h5" gutterBottom component="div">
          {auth?.title ? auth?.title : "Mideye+ Touch Accept login"}
        </Typography>
        <Typography variant="body1" gutterBottom component="div">
          {info.map((value, index) => (
            <div key={index}>
              {value}
              <br />
            </div>
          ))}
        </Typography>
      </div>
      {state === AuthenticationState.PENDING && (
        <>
          {auth.challenges && auth.challenges.length > 0 && (
            <div className="challenges">
              <TouchAcceptChallenges
                challenges={auth.challenges as ChallengeRequest[]}
                setTouchAcceptReplies={(touchAcceptReplies: Map<string, string>): void => {
                  setTouchAcceptReplies(touchAcceptReplies);
                  setFormValid(isFormValid(auth, touchAcceptReplies));
                }}
                touchAcceptReplies={touchAcceptReplies}
              />
            </div>
          )}

          <div className="progress">
            <CircularProgressWithLabel
              createdAt={createdAt}
              expiresAt={expiresAt}
              state={state}
              setState={setState}
              setInfo={setInfo}
            />
          </div>
          {approvers ? (
            <AssistedLoginButtons
              auth={auth}
              callback={onRespondToTA}
              setTouchAcceptReplies={setTouchAcceptReplies}
              touchAcceptReplies={touchAcceptReplies}
              approvers={approvers}
              formValid={formValid}
              sending={sending}
            />
          ) : (
            <TouchAcceptButtons
              auth={auth}
              callback={onRespondToTA}
              touchAcceptReplies={touchAcceptReplies}
              formValid={formValid}
              sending={sending}
            />
          )}
          <div className="session-info">
            <Typography variant="caption" gutterBottom component="div">
              Session ID:
              <br />
              {id}
            </Typography>
          </div>
        </>
      )}
      {(state === AuthenticationState.ACCEPTED || state === AuthenticationState.REJECTED) && auth && activationCode && (
        <ActivateMideyePlus auth={auth} activationCode={activationCode} />
      )}
    </div>
  );
};
