import { API, graphqlOperation } from "aws-amplify";
import { ApproverInfo, Auth, ChallengeRequest, ChallengeType, GetAuthQuery } from "../API";
import { AuthenticationState } from "../enums/authentication-state";
import { getAuth } from "../graphql/queries";
import { GraphQLResult } from "@aws-amplify/api-graphql";

type FetchAuthSessionResponse = {
  auth: Auth;
  info: string[];
  state: AuthenticationState;
  activationCode: string;
};

export async function queryAuth(id: string, r?: string): Promise<FetchAuthSessionResponse> {
  let response: FetchAuthSessionResponse = {
    auth: {} as Auth,
    info: [],
    state: AuthenticationState.ERROR,
    activationCode: "",
  };
  try {
    const authSessionResult = (await API.graphql(graphqlOperation(getAuth, { id }))) as GraphQLResult<GetAuthQuery>;
    const authResponse = authSessionResult?.data?.getAuth;

    if (!authResponse) {
      const error = Error("Authentication ID is unknown");
      error.name = "Empty authentication response received.";
      throw error;
    }

    // 'createdAt' is required
    if (!authResponse?.createdAt) {
      const error = Error("Invalid response");
      error.name = "Expected `createdAt` to be set";
      throw error;
    }

    // 'expiresAt' is required
    if (!authResponse?.expiresAt) {
      const error = Error("Invalid response");
      error.name = "Expected `expiresAt` to be set";
      throw error;
    }

    // activation reference id from SMS
    if (r) {
      response.activationCode = r;
    }

    // Error from Amplify?
    if (authSessionResult.errors) {
      const error = Error("Invalid response");
      error.name = "Failed the fetch authentication from backend";
      throw error;
    }

    if (authResponse) {
      let infoLines = authResponse.touchAcceptInfo as Array<string>;
      if (infoLines) {
        response.info = infoLines;
      }
      response.auth = authResponse;

      const expiresAt = Date.parse(authResponse.expiresAt);
      if (expiresAt < Date.now()) {
        console.log(
          "Authentication session has expired: ",
          authResponse.expiresAt,
          new Date(authResponse.expiresAt).toUTCString()
        );
        response.info = ["Authentication expired"];
        response.state = AuthenticationState.EXPIRED;
      } else {
        response.state = AuthenticationState.PENDING;
      }
    }
  } catch (e: unknown) {
    console.log("error fetching auth session with id", id, e);
    if (e instanceof Error) {
      response.info = e.message ? [e.message] : ["Failed to fetch authentication payload from backend."];
    }
    response.state = AuthenticationState.ERROR;
  }
  return response;
}

export function parseApprovers(authSession: Auth): Array<ApproverInfo> | undefined {
  // New way of storing approvers
  if (Boolean(authSession?.approvers?.length)) {
    return authSession.approvers as Array<ApproverInfo>;
  } else if (Boolean(authSession.challenges?.length)) {
    // Old way of storing approvers
    const approvers: Array<ApproverInfo> = [];
    const approverChallenges = authSession.challenges?.filter((challenge) => challenge?.type === ChallengeType.SELECT);
    if (approverChallenges && approverChallenges[0]) {
      const approverChallenge = approverChallenges[0];
      approverChallenge.choices?.forEach((choice) => {
        if (choice) {
          const approver = {
            id: choice.id,
            label: choice.label,
            description: choice.label,
            msisdn: "+234234234",
          } as ApproverInfo;
          approvers.push(approver);
        }
      });
    }
    return approvers;
  } else {
    return undefined;
  }
}

export function parseChallenges(challenges: Array<ChallengeRequest | null>): Map<string, string> | undefined {
  if (challenges && challenges.length) {
    const touchAcceptReplies = new Map<string, string>();
    challenges
      .filter((value) => value?.type !== ChallengeType.SELECT)
      .forEach((challenge) => {
        if (challenge) {
          touchAcceptReplies.set(challenge.id, "");
        }
      });
    return touchAcceptReplies;
  }
  return undefined;
}

/**
 * Validates a form.
 * @param auth Auth object
 * @param touchAcceptReplies Map of touch accept replies
 * @returns true if form is valid
 */
export const isFormValid = (auth: Auth | undefined, touchAcceptReplies: Map<string, string> | undefined): boolean => {
  let result = true;
  if (!auth) {
    return false;
  }
  touchAcceptReplies?.forEach((value, key) => {
    if (!value) {
      result = false;
    }
  });
  return result;
};
